/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { AdminGet } from "../../../services/ApiService";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import styles from "../Dashboard.module.css";

const ActiveClassesMetric = () => {
  const today = new Date();
  const defaultFromDate = new Date(today.setFullYear(today.getFullYear() - 1))
    .toISOString()
    .split("T")[0];
  const defaultToDate = new Date(today.setFullYear(today.getFullYear() + 2))
    .toISOString()
    .split("T")[0];

  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [productType, setProductType] = useState("All");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const fetchData = async () => {
    setLoading(true);
    const response = await AdminGet(
      `Dashboard/ActiveClasses?FromDate=${fromDate}&ToDate=${toDate}&ProductType=${productType}`
    );
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // Remove the dependency array to ensure it only runs once

  const handleRefresh = () => {
    if (new Date(toDate) <= new Date(fromDate)) {
      setError("To date must be later than from date.");
      return;
    }
    setError("");
    fetchData();
  };

  const formatDate = (dateString) => {
    const options = { month: "2-digit", day: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className={styles.tableContainer}>
      <InputGroup>
        <Input
          className={`${styles.inputFilter} ${styles.inputDateFilter}`}
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        <Input
          className={`${styles.inputFilter} ${styles.inputDateFilter}`}
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className={styles.inputFilter}>
          <DropdownToggle caret className={styles.dropDownToggle}>{productType}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setProductType("All")}>
              All
            </DropdownItem>
            <DropdownItem onClick={() => setProductType("SimProject")}>
              SimProject
            </DropdownItem>
            <DropdownItem onClick={() => setProductType("SimAgile")}>
              SimAgile
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <InputGroupAddon addonType="prepend">
          <Button
            color="primary"
            onClick={handleRefresh}
            className={styles.refreshButton}
          >
            <i className="fa fa-refresh" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
      {error && <div className={styles.errorLabel}>{error}</div>}
      {loading ? (
        <div className={styles.loadingLabel}>Loading...</div>
      ) : (
        <Table striped className={styles.tableDashboardResults}>
          <thead>
            <tr>
              <th>Classroom Name</th>
              <th>Primary Instructor</th>
              <th>Students</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((classroom, index) => (
              <tr key={index}>
                <td>{classroom.classroomName}</td>
                <td>{classroom.primaryInstructor}</td>
                <td>{classroom.studentsCount}</td>
                <td>{formatDate(classroom.classroomStartDate)}</td>
                <td>{formatDate(classroom.classroomEndDate)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ActiveClassesMetric;
