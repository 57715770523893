/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { AdminGet } from "../../../services/ApiService";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styles from "../Dashboard.module.css";

const getChartOptions = () => {
  return {
    title: "Unique Active Instructors",
    titleTextStyle: {
      color: "#333",
      fontSize: 16,
      fontName: "Verdana",
    },
    lineWidth: 2,
    pointSize: 2,
    hAxis: {
      title: "Date",
      format: "MMM dd, yyyy",
      gridlines: { count: -1 },
      textStyle: {
        color: "#333",
        fontName: "Verdana",
        fontSize: 12,
      },
    },
    vAxis: {
      title: "Instructors",
      textStyle: {
        color: "#333",
        fontName: "Verdana",
        fontSize: 12,
        italic: false,
      },
    },
    series: {},
  };
};

const ActiveInstructorsMetric = () => {
  const today = new Date();
  const defaultFromDate = new Date(today.setFullYear(today.getFullYear() - 1))
    .toISOString()
    .split("T")[0];
  const defaultToDate = new Date(today.setFullYear(today.getFullYear() + 2))
    .toISOString()
    .split("T")[0];

  const [data, setData] = useState([["Date", "Simproject", "SimAgile"]]);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [productType, setProductType] = useState("All");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [chartOptions, setChartOptions] = useState(getChartOptions());

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const fetchData = async () => {
    setLoading(true);
    const response = await AdminGet(
      `Dashboard/ActiveInstructors?FromDate=${fromDate}&ToDate=${toDate}&ProductType=${productType}`
    );
    const apiData = response.data.map((item) => {
      if (productType === "SimProject") {
        return [item.date, item.simProjectInstructors];
      } else if (productType === "SimAgile") {
        return [item.date, item.simAgileInstructors];
      } else {
        return [item.date, item.simProjectInstructors, item.simAgileInstructors];
      }
    });
    if (productType === "SimProject" || productType === "SimAgile") {
      setData([["Date", productType], ...apiData]);
    } else {
      setData([["Date", "SimProject", "SimAgile"], ...apiData]);
    }

    let chartSeries = {};
    if (productType === "SimProject") {
      chartSeries = {
        0: { color: "red" }
      };
    } else if (productType === "SimAgile") {
      chartSeries = {
        0: { color: "green" }
      };
    } else {
      chartSeries = {
        0: { color: "red" },
        1: { color: "green" }
      };
    }
    setChartOptions({
      title: "Unique Active Instructors",
      titleTextStyle: {
        color: "#333",
        fontSize: 16,
        fontName: "Verdana",
      },
      lineWidth: 2,
      pointSize: 2,
      hAxis: {
        title: "Date",
        format: "MMM dd, yyyy",
        gridlines: { count: -1 },
        textStyle: {
          color: "#333",
          fontName: "Verdana",
          fontSize: 12,
        },
      },
      vAxis: {
        title: "Instructors",
        textStyle: {
          color: "#333",
          fontName: "Verdana",
          fontSize: 12,
          italic: false,
        },
      },
      series: chartSeries,
    });

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // Remove the dependency array to ensure it only runs once

  const handleRefresh = () => {
    if (new Date(toDate) <= new Date(fromDate)) {
      setError("To date must be later than from date.");
      return;
    }
    setError("");
    fetchData();
  };

  return (
    <div className={styles.chartContainer}>
      <InputGroup>
        <Input
          className={`${styles.inputFilter} ${styles.inputDateFilter}`}
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        <Input
          className={`${styles.inputFilter} ${styles.inputDateFilter}`}
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className={styles.inputFilter}>
          <DropdownToggle caret className={styles.dropDownToggle}>{productType}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setProductType("All")}>
              All
            </DropdownItem>
            <DropdownItem onClick={() => setProductType("SimProject")}>
              SimProject
            </DropdownItem>
            <DropdownItem onClick={() => setProductType("SimAgile")}>
              SimAgile
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <InputGroupAddon addonType="prepend">
          <Button
            color="primary"
            onClick={handleRefresh}
            className={styles.refreshButton}
          >
            <i className="fa fa-refresh" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
      {error && <div className={styles.errorLabel}>{error}</div>}
      {loading ? (
        <div className={styles.loadingLabel}>Loading...</div>
      ) : (
        <Chart
          chartType="LineChart"
          data={data}
          options={chartOptions}
          width="100%"
          height="400px"
          legendToggle
        />
      )}
    </div>
  );
};

export default ActiveInstructorsMetric;
