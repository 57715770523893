import React, { useState, Fragment } from "react";
import {  
  Row,
  Col,
  TabPane,
  TabContent,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import classnames from "classnames";
import ActiveInstructorsMetric from "./components/ActiveInstructorsMetric";
import TopInstructorsMetric from "./components/TopInstructorsMetric";
import ActiveClassesMetric from "./components/ActiveClassesMetric";

const Dashboard = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [loadedTabs, setLoadedTabs] = useState({ "1": true, "2": false, "3": false });

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (!loadedTabs[tab]) {
        setLoadedTabs({ ...loadedTabs, [tab]: true });
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({active: activeTab === "1"})} onClick={() => toggleTab("1")}>
                Active Instructors
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({active: activeTab === "2"})} onClick={() => toggleTab("2")}>
                Top Instructors
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({active: activeTab === "3"})} onClick={() => toggleTab("3")}>
                Active Classes
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {loadedTabs["1"] && <ActiveInstructorsMetric />}
            </TabPane>
            <TabPane tabId="2">     
              {loadedTabs["2"] && <TopInstructorsMetric />}
            </TabPane>
            <TabPane tabId="3">     
              {loadedTabs["3"] && <ActiveClassesMetric />}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Dashboard;
